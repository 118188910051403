import React, { useState, useEffect } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import CurrencyInput from "react-currency-input-field"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"

const stopProp = e => {
  e.stopPropagation()
}

const GetInContactModal = ({ removeOverlay }) => {
  const [name, setName] = useState("")
  const [emailAdd, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [companyWebsite, setCompanyWebsite] = useState("")
  const [revenue, setRevenue] = useState("0.00")
  const [error, isError] = useState(false)

  const [confirm, setConfirm] = useState(false)

  const handleCloseModal = () => {
    removeOverlay()
    setConfirm(false)
  }

  async function doProcess() {
    try {
      let payload = {
        name: name,
        workEmailAddress: emailAdd,
        phoneNumber: phoneNumber,
        companyWebsite: companyWebsite,
        avgMothlyRevenue: Number(revenue).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
      }

      let { data: token } = await axios.get(
        "https://billsby-mailgun.onrender.com/email-service/token"
      )

      await axios
        .post(
          "https://billsby-mailgun.onrender.com/email-service/marketing",
          payload,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(({ data }) => {
          setConfirm(true)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {}, [])

  const handleSubmit = () => {
    doProcess()
  }

  return (
    <div className="overlay_background modal" onClick={handleCloseModal}>
      <div className="overlay_card overlay_card--sm" onClick={e => stopProp(e)}>
        <div className="modal-container">
          <button
            className="btn-close"
            onClick={e => {
              e.preventDefault()
              handleCloseModal()
            }}
          >
            <FontAwesomeIcon icon={faTimes} className="close-icon" />
          </button>
          {confirm ? (
            <div className={"first-col-2"}>
              <div className="modal-text-wrapper">
                <h2 className="modal-title">
                  You're all set {name}! We'll get in touch with you soon
                </h2>
                <p className="modal-description">
                  Whilst you're waiting for a follow up, why not sign up to Billsby? There's{" "}
                  <span className="bold">no credit card required</span> until you go live.
                </p>
                <p className="modal-description">
                  It only takes a couple of minutes to sign up and the best way to get to know Billsby is to try it for yourself.
                </p>
              </div>
              <div className="modal-btn-wrapper">
                <a
                  href="https://app.billsby.com/registration"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-orange"
                >
                  Get started with Billsby today
                </a>
              </div>
              <div className="modal-image-wrapper">
                <div className="modal-image-holder">
                  <img
                    src={require("../images/free-trial.svg")}
                    className="modal-image"
                    alt="free-trial"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={"first-col-2"}>
              <div className="modal-text-wrapper">
                <h2 className="modal-title">
                  Our team can't wait to show you how Billsby can help your
                  business to succeed.
                </h2>
                <p className="modal-description">
                  We only need a few bits of information so our executive level
                  sales team can get into contact with you about how Billsby can
                  work best for your business
                </p>
              </div>
              <div className="form-wrapper">
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className={`input-field ${error ? "error" : ""}`}
                      placeholder="Name"
                      name="name"
                      value={name}
                      onChange={event => setName(event.target.value)}
                      required
                    />

                    <input
                      type="email"
                      className={`input-field ${error ? "error" : ""}`}
                      placeholder="Work Email Address"
                      name="emailAdd"
                      value={emailAdd}
                      onChange={event => setEmail(event.target.value)}
                      required
                    />

                    {/* <PhoneInput
                      // className={`input-field ${error ? "error" : ""}`}
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                    /> */}

                    <input
                      type="number"
                      className={`input-field ${error ? "error" : ""}`}
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={event => setPhoneNumber(event.target.value)}
                      required
                    />

                    <input
                      type="text"
                      className={`input-field ${error ? "error" : ""}`}
                      placeholder="Company Website"
                      name="companyWebsite"
                      value={companyWebsite}
                      onChange={event => setCompanyWebsite(event.target.value)}
                      required
                    />

                    <CurrencyInput
                      required
                      className={`input-field ${error ? "error" : ""}`}
                      onValueChange={(value, name) => setRevenue(value)}
                      // fixedDecimalLength={2}
                      decimalsLimit={2}
                      placeholder="Average Monthly Revenue"
                      name="averageMonthlyRevenue"
                      // value={revenue}
                      allowNegativeValue={false}
                      prefix={"$"}
                      maxLength={13}
                      step={100}
                    />

                    <button type="submit" className={`btn-orange`}>
                      Submit
                    </button>
                  </div>
                </form>
                <p className="modal-text">
                  Please allow 3 working days for us to get into contact with
                  you
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GetInContactModal
