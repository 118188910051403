import React, { useState } from "react"
import classnames from "classnames"
import GetInContactModal from "./get-in-contact-modal"
function scrollLock() {
  document.body.style.overflow = "hidden"
  document.body.style.position = "fixed"
  document.body.style.left = "0"
  document.body.style.right = "0"
}

function removeScrollLock() {
  document.body.style.overflow = "visible"
  document.body.style.position = "initial"
  document.body.style.left = "initial"
  document.body.style.right = "initial"
}

const GetInContactButton = props => {
  // eslint-disable-next-line
  const [overlay, setOverlay] = useState(false)
  const withModal = props.withModal

  const closeModal = () => {
    setOverlay(false)
    removeScrollLock()
  }

  const redirectToExternal = () => {
    window.location.href =
      "https://calendly.com/billsby/pro-development-services-discovery-meeting"
  }

  return (
    <>
      {withModal && (
        <div
          id="modal-contact"
          className={overlay ? "overlay_shown" : "overlay_hidden"}
        >
          <GetInContactModal removeOverlay={closeModal} />
        </div>
      )}
      <button
        className={classnames(
          "btn",
          { "btn-black": props.buttonColor === "black" },
          { "btn-orange": props.buttonColor === "orange" }
        )}
        onClick={() => {
          if (withModal) {
            scrollLock()
            setOverlay(true)
          } else {
            redirectToExternal()
          }
        }}
      >
        {props.buttonText}
      </button>
    </>
  )
}

export default GetInContactButton
